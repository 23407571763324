import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import moment from 'moment/min/moment-with-locales';

import Layout from '../components/layout';
import SubpageHeader from "../components/subpage-header/subpage-header";
import Container from "../components/container/container";

import '../styles/article.scss';
import Link from "../components/link";
import slugify from "../utils/slugify";

export const query = graphql`
  query BlogQuery($id: String!) {
    article: strapiBlog(strapiId: { eq: $id }) {
        postTitle
        postContent
        published_at
        categories {
            categoryName
            simpleCategoryName
        }
        postThumbnail {
            childImageSharp {
                fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
    latestArticles: allStrapiBlog(limit: 5) {
        nodes {
            postTitle
            postThumbnail {
                childImageSharp {
                    fixed(width: 50, height: 50) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            strapiId
        }
    }
    categories: allStrapiCategories {
        nodes {
          categoryName
          simpleCategoryName
          strapiId
        }
    }
  }
`

const ArticleTemplate = ({data}) => {
    moment.locale('pl');
    const articleDate = `${moment(data.article.published_at).format('LL')}`;
    return (
        <Layout title={data.article.postTitle}>
            <section className="single-article">
                <SubpageHeader title="Blog" bg/>
                <Container>
                    <div className="content">
                        <BackgroundImage Tag="div" className="article-thumbnail" fluid={data.article.postThumbnail.childImageSharp.fluid} />
                        <div className="article-meta">
                            <span className="article-category">{data.article.categories.categoryName}</span>
                            <span className="article-date">{articleDate}</span>
                        </div>
                        <h1>{data.article.postTitle}</h1>
                        <ReactMarkdown source={data.article.postContent} />
                        <div className="pagination">
                            <Link to={`/blog`} rel="prev">
                                ← Wróć
                            </Link>
                        </div>
                    </div>
                    <div className="sidebar">
                        <div className="sidebar-wrapper">
                            <div className="title">Kategorie</div>
                            <div className="all-categories">
                                <ul>
                                    {data.categories.nodes.map(category => <li key={category.strapiId}><Link to={`/blog/kategoria/${category.simpleCategoryName}`}>{category.categoryName}</Link></li>)}
                                </ul>
                            </div>
                            <div className="title">Najnowsze wpisy</div>
                            <div className="latest-articles">
                                <ul>
                                    {data.latestArticles.nodes.map(article => (
                                        <li key={article.strapiId}>
                                            <Link to={`/blog/${slugify(article.postTitle)}`}>
                                                <Img fixed={article.postThumbnail.childImageSharp.fixed} />
                                                {article.postTitle}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </Layout>
    )
}

export default ArticleTemplate;